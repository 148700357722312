<template>
    <v-container fluid>
        <PageHeaderSlot showBackButton> </PageHeaderSlot>

        <v-form ref="dataForm" @submit.prevent="save()">
            <v-card>
                <v-card-text class="px-2">
                    <v-row class="ma-0">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="貨品編號" 
                                placeholder="貨品編號"
                                :fieldValue.sync="formData.product_code" 
                                dense 
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="分校" 
                                placeholder="選擇分校"
                                :fieldValue.sync="formData.school_id" 
                                dense 
                                :options="tutorSchoolOptions"
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="商品狀態" 
                                :fieldValue.sync="formData.status" 
                                dense 
                                :options="productStatusOptions"
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="商品名稱" 
                                placeholder="商品名稱"
                                :fieldValue.sync="formData.name" 
                                dense 
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="類別" 
                                placeholder="選擇類別"
                                :fieldValue.sync="formData.category_id" 
                                dense 
                                :options="categoryOptions"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                type="number"
                                isIntegerOnly
                                label="庫存" 
                                placeholder="庫存"
                                :fieldValue.sync="formData.inventory" 
                                dense
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                type="number"
                                label="價格" 
                                placeholder="價格"
                                :fieldValue.sync="formData.price" 
                                dense
                                prefix="HK$"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                type="number"
                                label="成本" 
                                placeholder="成本"
                                :fieldValue.sync="formData.cost" 
                                dense
                                prefix="HK$"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" v-if="$validate.DataValid(id)">
                            <label class="input-label">銷量</label>
                            <span class="colorBlack--text d-flex align-center" style="height: 40px;">{{ formData.sale }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <div class="d-flex align-center">
                <v-btn type="submit" class="primary mt-4" text depressed :loading="$store.getters.isLoading">儲存</v-btn>
                <v-btn v-if="$validate.DataValid(id)" type="button" class="mt-4 ml-4" outlined color="error" text depressed :loading="$store.getters.isLoading" @click="handleDelete()">刪除</v-btn>
            </div>
        </v-form>

        <ConfirmDialog ref="confirmDeleteDialog" title="是否刪除此商品？" deleteDescription="是否確定要刪除此商品？此動作無法復原。"></ConfirmDialog>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import FormInput from '@/components/formField/FormInput.vue';
import FormSelect from '@/components/formField/FormSelect.vue';

export default {
    name: 'ProductDetails',
    components: {
        ConfirmDialog,
        FormInput,
        FormSelect,
    },
    computed: { 
        id() {
            const id = parseInt(this.$route.params.id);
            if (!isNaN(id)) {
                return id;
            }

            return null;
        },
        dispatchUpdateOnChange() {
            if (this.$validate.DataValid(this.id)) {
                return true;
            } else {
                return false;
            }
        }
    },
    data: () => ({
        productStatusOptions: [
            { value: 'active', text: '啟用' },
            { value: 'inactive', text: '停用 ' }
        ],
        formData: {
            product_code: '',
            school_id: '',
            status: 'active', // 'active' | 'inactive'
            name: '',
            category_id: '',
            inventory: '',
            price: '',
            cost: '',
            sale: '',
        },
        tutorSchoolOptions: [],
        categoryOptions: [],
    }),
    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getProductById() {
            try {
                const data = await this.$Fetcher.GetProductById(this.id);
                const numToStrKeys = ['inventory', 'price', 'cost'];
                for(const key in this.formData) {
                    if (this.$validate.DataValid(data[key])) {
                        if (numToStrKeys.includes(key)) {
                            this.formData[key] = (data[key]).toString();
                        } else {
                            this.formData[key] = data[key];
                        }
                    }
                }

                const sale = await this.$Fetcher.GetProductSale(this.id);
                this.formData.sale = sale;
            } catch {
                this.setDialogMessage({
                    message: 'noData',
                    returnLink: {name: 'ProductList'}
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);

            const valid = this.$refs['dataForm'].validate();
            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            const payload = {
                school_id: this.formData.school_id,
                status: this.formData.status,
                product_code: this.formData.product_code,
                name: this.formData.name,
                category_id: this.$validate.DataValid(this.formData.category_id) ? this.formData.category_id : -1,
                inventory: this.$validate.DataValid(this.formData.inventory) ? parseInt(this.formData.inventory) : 0,
                price: this.$validate.DataValid(this.formData.price) ? parseFloat(this.formData.price) : 0,
                cost: this.$validate.DataValid(this.formData.cost) ? parseFloat(this.formData.cost) : 0,
            };

            if (this.$validate.DataValid(this.id)) {
                try {
                    await this.$Fetcher.UpdateProduct(this.id, payload);
                    this.$store.dispatch('setDataIsUpdated', false);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '儲存成功',
                        type: 'success',
                        refresh: true,
                        redirect: ''
                    });
                } catch(err) {
                    this.setDialogMessage({
                        title: "儲存失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            } else {
                try {
                    await this.$Fetcher.NewProduct(payload);
                    this.$store.dispatch('setDataIsUpdated', false);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '新增成功',
                        type: 'success',
                        refresh: false,
                        redirect: 'ProductList'
                    });
                
                } catch (err) {
                    this.setDialogMessage({
                        title: "新增失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },
     

        async handleDelete() {
            const confirm = await this.$refs.confirmDeleteDialog.show();
            if (confirm) {
                this.$store.dispatch('setLoading', true);
              
                try {
                    await this.$Fetcher.DeleteProduct(this.id);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '刪除成功',
                        type: 'success',
                        refresh: false,
                        redirect: 'ProductList'
                    });
                } catch (err) {
                    this.setDialogMessage({
                        title: "刪除失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },

        // ======== 分校 Options =======
        async getAllSchoolOptions() {
            try {
                const { data } = await this.$Fetcher.GetSchools();
                const filtered = data.filter(el => el.active === true);
                    if (this.$validate.DataValid(filtered)) {
                        this.tutorSchoolOptions = filtered.map(el => {
                        return {
                            value: el.id,
                            text: el.name
                        }
                    })
                }
            } catch {}
        },

        // ======== 類別 Options =======
        async getAllProductCategoryOptions() {
            try {
                const { data } = await this.$Fetcher.GetProductCategories();
                const filtered = data.filter(el => el.active === true);
                    if (this.$validate.DataValid(filtered)) {
                        this.categoryOptions = filtered.map(el => {
                        return {
                            value: el.id,
                            text: el.name
                        }
                    })
                }
            } catch {}
        },
    },
    async mounted() {
        await this.getAllSchoolOptions();
        await this.getAllProductCategoryOptions();
    },

    async created() {
        this.$store.dispatch('toggleLoadingPage', true);

        if (this.$validate.DataValid(this.id)) { // edit
            this.getProductById();
        } else { // new
            this.$store.dispatch('toggleLoadingPage', false);
        }

    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>